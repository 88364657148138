import httpClient from './httpClient';

const isRefundCheckEnqueued = (tripId: string, ticketId: string) =>
  httpClient.get(`trip/${tripId}/ticket/${ticketId}/refund_check`);

const refundCheckTrip = (tripId: string, ticketId: string) =>
  httpClient.post(`trip/${tripId}/ticket/${ticketId}/refund_check`, {
    reason: 'cancelled_involuntarily',
  });

const requestRefundSessionProduct = (
  sessionId: string,
  productUniqueId: number,
  productType: string
) =>
  httpClient.post(`session/${sessionId}/${productUniqueId}/request_refund`, {
    productType,
  });

export { isRefundCheckEnqueued, refundCheckTrip, requestRefundSessionProduct };
