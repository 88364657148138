
import { useRoute } from 'vue-router';
import { ref, computed } from '@vue/reactivity';
import { watch } from '@vue/runtime-core';
import {
  MinusIcon,
  ChevronRightIcon,
  UserIcon,
  ReceiptRefundIcon,
} from '@heroicons/vue/outline';
import { RefreshIcon } from '@heroicons/vue/solid';
import store from '@/store';
import { getSession } from '@/api/sessions.api';
import { requestRefundSessionProduct } from '@/api/refund.api';
import SystemStatusBadge from '@/components/SystemStatusBadge.vue';
import {
  removeUnderscores,
  capitalizeFirstLetter,
  formatDate,
} from '@/components/utils';
import { Session } from '@/models';
import { nonRefundableProductStatusesV1 } from '@/utils/constants';

export default {
  name: 'session',
  components: {
    MinusIcon,
    ChevronRightIcon,
    UserIcon,
    RefreshIcon,
    SystemStatusBadge,
    ReceiptRefundIcon,
  },
  setup() {
    const route = useRoute();
    const sessionId = ref(route.params.id as string);
    const session = ref({} as Session);
    const executed = ref(false);
    const loading = ref(false);
    const error = ref('');
    const selectedProductUniqueId = ref('');
    const selectedProductType = ref('');
    const user = store.state.user;

    const showRefundButton = computed(() => {
      try {
        let product = (
          (session.value as unknown as Record<string, unknown>)
            .products as Record<string, any>[]
        ).find((item) => item.uniqueId === selectedProductUniqueId.value);

        return (
          String(selectedProductUniqueId.value).length > 0 &&
          !!product?.status &&
          !nonRefundableProductStatusesV1.has(product?.status)
        );
      } catch (error) {
        console.log(error);
        return false;
      }
    });

    const fetchSession = async () => {
      loading.value = true;
      try {
        const response = await getSession(sessionId.value);
        const sessionData = response.data;
        sessionData.products = sessionData.products.filter(
          (p: any) => ['offered', 'accepted'].indexOf(p.status) === -1
        );
        session.value = sessionData;
        if ('integrationId' in response.data) {
          const integration = store.findIntegrationById(
            response.data.integrationId
          );
          if (integration) store.setActiveIntegration(integration);
        }
      } catch (e) {
        session.value = {} as Session;
        error.value = `Error: the server responded with '${
          (e as any).response.status
        }: ${(e as any).response.statusText}'`;
      }
      executed.value = true;
      loading.value = false;
    };

    const getPaxReferenceName = (paxId: string) => {
      if (session.value) {
        const bookingRecord = session.value.bookingRecords.find(
          (bookingRecord) => bookingRecord.paxNames[paxId]
        );
        return bookingRecord?.referenceName || 'No Pax Name';
      }
      return '';
    };

    const selectProduct = (uniqueId: string, productType: string) => {
      if (selectedProductUniqueId.value === uniqueId) {
        selectedProductUniqueId.value = '';
        selectedProductType.value = '';
      } else {
        selectedProductUniqueId.value = uniqueId;
        selectedProductType.value = productType;
      }
    };

    const requestRefund = async () => {
      try {
        await requestRefundSessionProduct(
          sessionId.value,
          Number(selectedProductUniqueId.value),
          selectedProductType.value
        );
        await fetchSession();
      } catch (e) {
        error.value = `Error requesting refund for session ${sessionId.value} product uniqueId ${selectedProductUniqueId.value}`;
      } finally {
        selectedProductUniqueId.value = '';
        selectedProductType.value = '';
      }
    };

    const fulfilledCount = computed(() => session.value.products?.length || 0);

    watch(
      () => route.params.id,
      (oldValue, newValue) => {
        sessionId.value = route.params.id as string;
        fetchSession();
      }
    );

    fetchSession();

    return {
      session,
      user,
      executed,
      loading,
      error,
      fetchSession,
      removeUnderscores,
      capitalizeFirstLetter,
      formatDate,
      selectProduct,
      selectedProductUniqueId,
      showRefundButton,
      requestRefund,
      getPaxReferenceName,
      fulfilledCount,
    };
  },
};
