const nonRefundableProductStatusesV1 = new Set([
  'checking_refund',
  'commission_refund_issued',
  'expired_refund_issued',
  'expired_refund_declined',
  'refund_declined',
  'refund_issued',
]);

export { nonRefundableProductStatusesV1 };
